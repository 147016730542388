import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import Logo from '../images/logo_e1transit_transparent.png';

const Header = ({ siteTitle }) => {
  return (
    <header>
      <div className="main-navigation">
        <div className="container">
          <div className="col-md-2">
            <Link to="/" className="main-logo">
              <img
                src={Logo}
                className="margin-topbottom-10"
                style={{ maxHeight: '50px' }}
                alt={siteTitle}
              />
            </Link>
          </div>
          <div className="col-md-10">&nbsp;</div>
        </div>
      </div>
    </header>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
