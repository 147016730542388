import React from 'react';
import { Link } from 'gatsby';

import FooterLogo from '../images/logo_e1transit_transparent.png';

const Footer = () => (
  <footer id="footer-home" className="footer padding-topbottom-35">
    <div className="container">
      <div className="col-md-offset-1 col-md-2">
        <h4>E1 Transit</h4>
        <ul className="nav">
          <li>
            <Link to="/about">Learn About E1</Link>
          </li>
          <li>
            <Link to="/about#vision">The Vision</Link>
          </li>
          <li>
            <Link to="/about#sustainability">E1 Transit Sustainability</Link>
          </li>
          <li>
            <Link to="/about#the-cars">Our Electric Vehicles</Link>
          </li>
        </ul>
      </div>
      <div className="col-md-2">
        <h4>Investor Relations</h4>
        <ul className="nav">
          <li>
            <Link to="/investors">Invest in E1</Link>
          </li>
          <li>
            <Link to="/partnerships">Partnerships</Link>
          </li>
        </ul>
      </div>
      <div className="col-md-2">
        <h4>Privacy</h4>
        <ul className="nav">
          <li>
            <Link to="/terms">Terms and Conditions</Link>
          </li>
          <li>
            <Link to="/privacy">Privacy Policy</Link>
          </li>
        </ul>
      </div>
      <div className="col-md-2">
        <h4>Bookings</h4>
        <ul className="nav">
          <li>
            <Link to="/corporate">Corporate Bookings</Link>
          </li>
        </ul>
      </div>
      <div className="col-md-2">
        <h4>Contact</h4>
        <ul className="nav">
          <li>
            <Link to="/contact">Contact</Link>
          </li>
          <li>
            <Link to="/media">Media</Link>
          </li>
          <li>
            <Link to="/social">Social Media</Link>
          </li>
        </ul>
      </div>

      <div className="clear"></div>

      <div
        className="col-md-offset-1 col-md-3 margin-topbottom-20"
        style={{ paddingLeft: '0px', marginRight: '25px' }}
      >
        <img src={FooterLogo} style={{ height: '50px' }} />
      </div>
      <div className="col-md-4 margin-topbottom-20"></div>
      <div className="col-md-3 margin-topbottom-20">
        <p className="copyright" style={{ marginTop: '10px' }}>
          &copy; 2022 E1 Transit Corporation
        </p>
      </div>
    </div>
  </footer>
);

export default Footer;
